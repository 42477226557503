
.popup {
    max-height: 300px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #ffffff;
}

.slider {
	overflow-y: hidden;
	transition-property: all;
	transition-duration: .5s;
	transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}
