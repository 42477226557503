

.actionContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    flex-direction: row;
}

.actionContainer > div {
    display: flex;
    flex-direction: column;
    align-items: inherit;
    flex: 2;
}

code {
    background-color: rgba(0, 0, 0, 0.07);
    padding: 1px 4px;
}

code > a {
    color: black;
}


@media only screen and (max-width: 600px) {
    .actionContainer{
        flex-direction: column;
    }
    .actionContainer > div {
        margin: 20px 0;
    }
}