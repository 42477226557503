
.header {
  background-color: rgb(255, 255, 255);
  height: 150px;
  padding: 20px;
  color: rgba(0, 0, 0, 0.699);
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  width: 100%;
  padding: 5px 20px;
}

.hidden {
  display: none;
}

